<template>
  <div :class="{'form-builder-controls-hidden': !showControls}">
    <v-layout row>
      <v-btn @click="addTab">Add tab</v-btn>
      <v-switch v-model="showControls" label="Show controls"></v-switch>
    </v-layout>
    <v-tabs>
      <v-tab v-for="group in groups" :key="group.id">
        {{group.label}}
      </v-tab>
      <v-tab-item v-for="(group, index) in groups" :key="group.id">
        <v-layout row class="form-builder-control">
          <v-toolbar text>
            <v-toolbar-title><v-text-field label="Label" v-model="group.label"></v-text-field></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text @click="addRow(group)"><v-icon>add</v-icon> Add row</v-btn>
              <v-btn text :disabled="index === 0" @click="shiftTab(index, -1)"><v-icon>arrow_left</v-icon></v-btn>
              <v-btn text :disabled="index == groups.length-1" @click="shiftTab(index, 1)"><v-icon>arrow_right</v-icon></v-btn>
              <v-btn text @click="removeTab(group)"><v-icon>delete</v-icon></v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-layout>
        <div class="row">
          <draggable
            v-model="group.rows"
            tag="v-layout"
            class="row wrap fill-height align-center sortable-list">

            <v-flex
              v-for="row in group.rows"
              :key="row.id"
              class="sortable"
              xs12
              my-2>

              <draggable
                :list="row.items"
                tag="v-layout"
                :group="{ name: 'row' }"
                class="row wrap">
                <v-layout column slot="header" style="flex: initial" class="form-builder-control">
                  <v-btn @click="addItem(row)">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-layout>
                <v-layout column slot="footer" style="flex: initial" class="form-builder-control">
                  <v-btn @click="removeRow(row, group)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-layout>
                <v-flex
                  v-for="item in row.items"
                  :key="item.id"
                  :style="{flex: item.w}">
                  <v-card>
                    <v-card-text>
                      <v-autocomplete
                        v-model="item.name"
                        :label="$t('attribute')"
                        :items="selectableAttributes"
                        item-text="label"
                        item-value="name"
                        outline>
                        <template v-slot:append>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-icon small v-on="on" class="form-builder-control">settings</v-icon>
                            </template>
                            <v-list>
                              <v-list-tile @click="item.ro = !item.ro">
                                <v-list-tile-title>{{$t('readonly')}}</v-list-tile-title>
                                <v-list-tile-action v-if="!item.ro">
                                  <v-icon>check_box_outline_blank</v-icon>
                                </v-list-tile-action>
                                <v-list-tile-action v-if="item.ro">
                                  <v-icon>check_box</v-icon>
                                </v-list-tile-action>
                              </v-list-tile>
                              <v-list-tile @click="item.w = (item.w - 1)" :disabled="item.w < 2">
                                <v-list-tile-title>{{$t('narrow')}}</v-list-tile-title>
                                <v-list-tile-action><v-icon>fullscreen_exit</v-icon></v-list-tile-action>
                              </v-list-tile>
                              <v-list-tile @click="item.w = (item.w + 1)">
                                <v-list-tile-title>{{$t('widen')}}</v-list-tile-title>
                                <v-list-tile-action><v-icon>fullscreen</v-icon></v-list-tile-action>
                              </v-list-tile>
                              <v-list-tile @click="removeItem(item, row)">
                                <v-list-tile-title>{{$t('remove')}}</v-list-tile-title>
                                <v-list-tile-action><v-icon>delete</v-icon></v-list-tile-action>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </template>
                      </v-autocomplete>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </draggable>
            </v-flex>
          </draggable>
        </div>
      </v-tab-item>
    </v-tabs>
    <!--<pre><code>{{JSON.stringify(schema.layout.form, null, 2)}}</code></pre>-->
    <!--<pre><code>{{JSON.stringify(groups, null, 2)}}</code></pre>-->
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['schema'],
  data: () => ({
    showControls: true,
    nextId: 0,
    groups: []
  }),
  components: {
    draggable
  },
  mounted () {
    this.groups = Object.values(JSON.parse(this.schema.layout.form || '[]'))
      .map(({label, rows}) => ({
        id: this.newId(),
        label,
        rows: rows.map(items => ({
          id: this.newId(),
          items: items.map(({name, w, ro}) => ({
            id: this.newId(),
            name, w, ro
          }))
        }))
      }))
  },
  computed: {
    selectableAttributes () {
      return this.schema.attributes.map(attribute => ({
        label: attribute.label || attribute.name,
        name: attribute.name,
        attribute
      }))
    }
  },
  watch: {
    groups: {
      deep: true,
      handler () {
        let layout = this.groups.map(({label, rows}) => ({
          label,
          rows: rows.map(({items}) => items.map(({w, ro, name}) => ({
            w,
            ro,
            name
          })).filter(({name}) => name)).filter(items => items.length)
        })).filter(({rows}) => rows.length)
        this.schema.layout.form = layout.length ? JSON.stringify(layout) : null
      }
    }
  },
  methods: {
    newId () { return ++this.nextId },
    addTab () {
      this.groups.push({
        id: this.newId(),
        label: 'New tab',
        rows: [{
          id: this.newId(),
          items: [{
            id: this.newId(),
            w: 1,
            ro: false,
            name: null
          }]
        }]
      })
    },
    removeTab (group) {
      this.groups.splice(this.groups.findIndex(g => g.id === group.id), 1)
    },
    shiftTab (index, delta) {
      this.groups.splice(index+delta, 0, this.groups.splice(index, 1)[0])
    },
    addRow (group) {
      group.rows.push({
        id: this.newId(),
        items: [{
          id: this.newId(),
          w: 1,
          ro: false,
          name: null
        }]
      })
    },
    removeRow (row, group) {
      group.rows.splice(group.rows.findIndex(r => r.id === row.id), 1)
    },
    addItem (row) {
      row.items.push({
        id: this.newId(),
        w: 1,
        ro: false,
        name
      })
    },
    removeItem (item, row) {
      row.items.splice(row.items.findIndex(i => i.id === item.id), 1)
    }
  }
}
</script>
<style>
.form-builder-controls-hidden .form-builder-control {
  display: none;
}
</style>
