<template>
  <layout-page>
    <template v-slot:toolbar-title>
      <v-toolbar-title>
        {{translate('Collections')}}
      </v-toolbar-title>
    </template>
    <template v-slot:toolbar-items>
      <v-toolbar-items>
        <v-btn
          :loading="collections.loading"
          text
          @click="save(collections.clientSettings)">
          <v-icon>save</v-icon> {{translate('Save', 'save')}}
        </v-btn>
      </v-toolbar-items>
    </template>
    <v-card>
      <v-card-text>
        <schemas-grid
          :loading="collections.loading"
          :schemas="collections.clientSettings.collections" />
      </v-card-text>
    </v-card>
  </layout-page>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import LayoutPage from '@/components/layout/layout-page.vue'
import SchemasGrid from './schemas-grid'

export default {
  data: () => ({
    mutations: 1
  }),
  components: {
    LayoutPage,
    SchemasGrid
  },
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'translate'])
  },
  asyncComputed: {
    collections: {
      async get () {
        let schemas = await this.api.collections.getCollectionSchemas()
        return {
          clientSettings: this.collectionsConfig.mapClientSettings(schemas),
        }
      },
      default: {
        loading: true,
        clientSettings: []
      }
    }
  },
  methods: {
    ...mapActions(['updateClientConfig']),
    async save (clientSettings) {
      let cs = this.collectionsConfig.normalizeClientSettings(clientSettings)
      return this.updateClientConfig (cs)
    }
  }
}
</script>
