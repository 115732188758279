<template>
  <v-simple-table
    v-sortable-table="'.drag-handle'"
    @drag-sorted="dragSorted"
    >
    <thead></thead>
    <tbody>
      <tr v-for="attribute in attributes" :key="attribute.name">
        <td class="drag-handle">
          <v-icon>swap_vert</v-icon>
          {{attribute.name}}
        </td>
        <td>
          <v-text-field label="Display name" :placeholder="attribute.label" v-model="attribute.label" />
        </td>
        <td>
          <v-switch v-model="attribute.list.show" :label="'Visa'"></v-switch>
        </td>
        <td>
          <v-switch v-model="attribute.list.load" :label="'Ladda'"></v-switch>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>
<script>
import SortableTable from '@/directives/sortable-table'
export default {
  props: ['attributes'],
  directives: {
    SortableTable
  },
  methods: {
    dragSorted ({newIndex, oldIndex} = event) {
      this.attributes.splice(newIndex, 0, this.attributes.splice(oldIndex, 1)[0])
    }
  }
}
</script>
<style>
  .drag-handle {
    cursor: grab;
  }
</style>
