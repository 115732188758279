<template>
  <v-data-table
    :loading="loading"
    v-sortable-table="'.drag-handle'"
    :items="schemas"
    item-key="collectionId"
    :disable-filtering="true"
    :disable-pagination="true"
    :hide-default-header="true"
    :hide-default-footer="true"
    :expand="false"
    @drag-sorted="dragSorted"
  >
    <template v-slot:body="{items}">
      <tbody>
        <tr v-for="schema in items" :key="schema.collectionId">
          <td class="drag-handle">
            <v-icon>swap_vert</v-icon>
            {{schema.collectionId}}
          </td>
          <td>
            <v-text-field label="Display name" :placeholder="schema.label" v-model="schema.label" />
          </td>
          <td style="width: 1%">
            <schema-dialog :schema="schema" />
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>
<script>
import SortableTable from '@/directives/sortable-table'
import SchemaDialog from './schema-dialog.vue'
export default {
  props: ['schemas', 'loading'],
  directives: {
    SortableTable
  },
  components: {
    SchemaDialog
  },
  methods: {
    dragSorted ({newIndex, oldIndex} = event) {
      this.schemas.splice(newIndex, 0, this.schemas.splice(oldIndex, 1)[0])
    }
  }
}
</script>
<style>
  .drag-handle {
    cursor: grab;
  }
</style>
