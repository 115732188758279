<template>
  <v-dialog
    v-model="dialog"
    fullscreen hide-overlay
    transition="dialog-bottom-transition"
    >
    <template v-slot:activator="{on}">
      <v-btn color="primary" small icon v-on="on">
        <v-icon>edit</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{collectionsConfig.getCollectionLabel(schema.collectionId)}}</v-toolbar-title>
        <v-spacer />
        <v-btn icon text @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-tabs>
          <v-tab :key="'attributes'">Names &amp; ordering</v-tab>
          <v-tab :key="'form'">Form layout</v-tab>
          <v-tab :key="'templates'">Display templates</v-tab>
          <v-tab-item :key="'attributes'">
            <attributes-grid :attributes="schema.attributes"></attributes-grid>
          </v-tab-item>
          <v-tab-item :key="'form'">
            <form-builder :schema="schema"></form-builder>
          </v-tab-item>
          <v-tab-item :key="'templates'">
            <v-textarea label="List thumbnail" v-model="schema.templates.list"></v-textarea>
            <v-textarea label="Details thumbnail" v-model="schema.templates.form"></v-textarea>

          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapGetters} from 'vuex'
import AttributesGrid from './attributes-grid'
import FormBuilder from './form-builder'
export default {
  props: ['schema'],
  data: () => ({
    dialog: false
  }),
  components: {
    AttributesGrid,
    FormBuilder
  },
  computed: {
    ...mapGetters(['collectionsConfig', 'translate'])
  }
}
</script>
<style>
  .drag-handle {
    cursor: grab;
  }
</style>
